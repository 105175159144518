.comments {
  background-color: #FFF;
  position: absolute;
  width: 100%;
  height: 40vh;
  left: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  border: solid 1px rgb(200, 200, 200);
  border-radius: 20px 20px 0 0;
  box-sizing: border-box;
}

.comments__header {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px 20px;
  box-sizing: border-box;
  background-color: #FFF;
  border-radius: 20px 20px 0 0;
}

.comments__title {
  font-weight: 500;
  font-size: 20px;
}

.comments__close {
  background: none;
  border: none;
  height: 24px;
  width: 24px;
  background-image: url("../../images/close\ black.svg");
  margin-left: auto;
  position: absolute;
  right: 20px;
  top: 20px;
}

.comments__main {
  overflow-y: scroll;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.comments__new {
  width: 100%;
  margin-top: auto;
  background-color: #F0F0F0;
  box-sizing: border-box;
  padding: 20px 40px;
}

.comments__input {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 0 20px;
  font-size: 14px;
}

.comments__send {
  position: absolute;
  bottom: 30px;
  right: 50px;
  height: 24px;
  width: 24px;
  background: none;
  border: none;
  background-image: url("../../images/send-alt.svg");
  display: none;
}

.comments__send_visible {
  display: block;
}