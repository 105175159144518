.banners {
  margin-top: 30px;
  background-color: #f8f2f2;
  z-index: -1;
}

.banners__main {
  width: 100vw;
  box-sizing: border-box;
  padding: 50px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.banners__main-bg {
  position: absolute;
  top: -30px;
  left: -30px;
  min-width: 110%;
  z-index: -1;
}

.banners__logo {
  font-family: 'Comfortaa', 'Segoe UI', Verdana, sans-serif;
  text-transform: lowercase;
  font-weight: 400;
  font-size: 160px;
  text-align: center;
  z-index: 0;
}

.banners__title {
  font-weight: 300;
  font-size: 60px;
  padding-bottom: 24px;
  max-width: 800px;
}

.banners__title_border {
  border-bottom: solid 1px #000;
  text-align: center;
  max-width: 100%;
  margin-bottom: 50px;
}

.banners__text {
  max-width: 500px;
}

.banners__text_main {
  max-width: 800px;
}

.banners__paragraph {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
}

.banners__paragraph_subtitle {
  max-width: 400px;
}

.banners__paragraph_separate {
  margin: 0 0 18px;
}

.banners__banner {
  padding: 50px;
}

.banners__image-container {
  max-height: 500px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banners__image {
  width: 100%;
}

.banners__info {
  display: flex;
  justify-content: space-between;
  padding-right: 200px;
  margin-top: 50px;
}


@media screen and (max-width: 600px) {
  .banners__main {
    padding: 20px;
  }

  .banners__logo {
    font-weight: 400;
    font-size: 70px;
  }

  .banners__title {
    font-size: 30px;
  }

  .banners__banner {
    padding: 20px;
  }

  .banners__info {
    flex-direction: column;
    padding: 0;
  }
}