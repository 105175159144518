.notification {
	position: fixed;
	bottom: 20px;
	right: 20px;
	padding: 16px 24px;
	border-radius: 8px;
	font-family: "Inter", Arial, sans-serif;
	font-size: 14px;
	z-index: 9999;
	min-width: 300px;
	max-width: 400px;
	animation: slideIn 0.3s ease-out forwards;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  .notification_success {
	background-color: #E8F5E9;
	border: 1px solid #C8E6C9;
	color: #388E3C;
  }
  
  .notification_error {
	background-color: #FFEBEE;
	border: 1px solid #FFCDD2;
	color: #D32F2F;
  }
  
  .notification__message {
	margin: 0;
  }
  
  @keyframes slideIn {
	from {
	  transform: translateX(100%);
	  opacity: 0;
	}
	to {
	  transform: translateX(0);
	  opacity: 1;
	}
  }
  
  @keyframes slideOut {
	from {
	  transform: translateX(0);
	  opacity: 1;
	}
	to {
	  transform: translateX(100%);
	  opacity: 0;
	}
  }
  
  .notification-exit {
	animation: slideOut 0.3s ease-in forwards;
  }