.modal {
  position: fixed;
  display: flex;
  visibility: hidden;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 25px #00000026;
  opacity: 0;
  transition: 
    visibility 0s 0.3s,
    opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.modal_opened {
  visibility: visible;
  opacity: 1;
  transition: 
    visibility 0s,
    opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.modal__container {
  position: relative;
  background-color: #fff;
  border-radius: 16px;
  min-width: 430px;
  padding: 43px 37px;
  box-sizing: border-box;
  transform: scale(0.9);
  opacity: 0;
  transition: 
    transform 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.modal_opened .modal__container {
  transform: scale(1);
  opacity: 1;
}

.modal__close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 24px;
  height: 24px;
  border: none;
  cursor: pointer;
  background-image: url("../../../images/close black.svg");
}

.modal__title {
  font-family: "Roboto", Arial, sans-serif;
  font-size: 24px;
  font-weight: 900;
  margin: 0;
}

.modal__alt {
  margin: 16px 0 0;
  font-family: "Inter", Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.modal__link-button {
  font-family: "Inter", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: rgb(100, 100, 100);
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

.modal__link-button:hover {
  color: rgb(200, 200, 200);
}

.modal__link-button:focus {
  color: rgb(200, 200, 200);
  text-decoration: underline;
}

.modal__link-button_large {
  font-size: 18px;
}

.modal__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 22px 0 0;
  max-height: 400px;
  overflow-y: scroll;
  max-height: 500px;
}

.modal__submit-button {
  width: 360px;
  height: 64px;
  background-color: rgb(200, 200, 200);
  font-family: "Roboto", Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #2c1822;
  border: none;
  border-radius: 32px;
  box-shadow: 0 5px 15px #0e1a3933;
  margin: 24px 0 0;
  cursor: pointer;
  transition: 
    background-color 0.2s ease-in-out,
    transform 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
}

.modal__submit-button:hover {
  background-color: rgb(170, 170, 170);
}

.modal__submit-button:active {
  background-color: rgb(200, 200, 200);
}

.modal__submit-button:hover:not(:disabled) {
  background-color: rgb(200, 200, 200);
  transform: translateY(-1px);
  box-shadow: 0 7px 20px #0e1a3955;
}

.modal__submit-button:active:not(:disabled) {
  background-color: rgb(200, 200, 200);
  transform: translateY(1px);
  box-shadow: 0 3px 10px #0e1a3922;
}

.modal__submit-button:disabled {
  background-color: #e6e8eb;
  color: #b6bcbf;
  box-shadow: none;
  cursor: not-allowed;
}

.modal__label {
  width: 100%;
  margin: 13px 0 0;
}

.modal__label:first-child {
  margin: 0;
}

.modal__label-text {
  font-family: "Inter", Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: rgb(100, 100, 100);
  margin: 0 0 1px;
}

.modal__input {
  width: 100%;
  font-family: "Inter", Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  border: none;
  padding: 9px 0 8px;
  caret-color: rgb(200, 200, 200);
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  transition: 
    border-color 0.2s ease-in-out,
    padding 0.2s ease-in-out;
}


.modal__input_error {
  border-bottom-color: #F00;
  padding-bottom: 7px;
}

.modal__input:focus {
  outline: none;
  border-bottom-color: rgb(200, 200, 200);
}

.modal__input_file {
  border-bottom: none;
}

.modal__input::placeholder {
  color: #000;
  opacity: 20%;
}

.modal__textarea {
  border-radius: 5px;
  border: solid 1px rgba(0, 0, 0);
  margin-top: 8px;
  width: 100%;
  height: 100px;
  padding: 8px;
  font-size: 14px;
  box-sizing: border-box;
}

.modal__textarea:focus {
  outline: none;
}

.modal__share-link {
  margin-top: 20px;
}

.modal__error {
  display: block;
  color: #F00;
  font-size: 12px;
  margin-top: 5px;
  font-family: "Inter", Arial, sans-serif;
  opacity: 0;
  transform: translateY(-10px);
  transition: 
    opacity 0.2s ease-in-out,
    transform 0.2s ease-in-out;
}

.modal__input_error + .modal__error {
  opacity: 1;
  transform: translateY(0);
}