.item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 190px;
  height: 304px;
  border: 1px solid rgb(230, 232, 241);
  transition: box-shadow 0.2s;
  text-decoration: none;
  box-sizing: border-box;
  cursor: pointer;
}

.item:hover {
  box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.1);
}

.item_small {
  width: 150px;
  height: 240px;
  flex-shrink: 0;
}

.item__image-container {
  height: 190px;
  min-height: 190px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}



.item__image {
  width: 100%;
}

.item__info {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: rgb(251, 251, 254);
  padding: 8px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-top: 1px solid rgb(230, 232, 241);
}

.item__name {
  font-weight: 500;
  font-size: 14px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 4px;
}

.item__price {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 4px;
}

.item__bottom {
  color: #404040;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid rgb(230, 232, 241);
}

.item__old-price {
  margin-right: 4px;
  color: #888;
  font-size: 14px;
  text-decoration: line-through;
}

.item__cart-button {
  margin-top: 8px;
  background-color: #fff;
  background-image: url("../../images/Plus.svg");
  background-size: cover;
  border: none;
  width: 22px;
  height: 22px;
  border-radius: 16px;
  position: absolute;
  top: 0;
  right: 8px;
  box-shadow: 0px 0px 5px rgb(0, 0, 0, 0.15);
  z-index: 1;
}

.item__like-button {
  height: 16px;
  width: 16px;
  background: none;
  border: none;
  background-image: url('../../images/like.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
}

.item__like-button_active {
  background-image: url('../../images/like\ active.svg');
}