.alice-carousel {
  display: flex;
  width: 100%;
  height: 310px;
  position: relative;
}

.alice-carousel__stage {
  display: flex;
  gap: 8px;
  position: relative;
  list-style: none;
  margin: 0;
  height: 100%;
  position: relative;
  width: calc(100vw - 500px);
}

@media screen and (max-width: 1415px) {
  .alice-carousel__stage {
    width: calc(100vw - 140px);
  }
}

.alice-carousel__wrapper {
  height: 100%;
}

.alice-carousel__dots {
  display: none
}

.alice-carousel__stage-item {
  margin: 0;
  max-width: 250px;
}
