.cookie-banner {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgb(60, 60, 60);
	z-index: 1000;
	padding: 20px 70px;
	opacity: 0.8;
  }
  
  .cookie-content {
	max-width: 1600px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
  }
  
  .cookie-text {
	color: white;
	margin: 0;
	font-size: 16px;
	line-height: 1.5;
	text-align: center;
  }
  
  .cookie-button {
	font-family: inherit;
	background-color: rgb(200, 200, 200);
	border: none;
	font-size: 20px;
	font-weight: 500;
	width: 160px;
	height: 50px;
	border-radius: 35px;
	box-shadow: 2px 3px 5px #27203650;
	transition: background-color 0.2s ease-in-out;
	color: #2c1822;
	cursor: pointer;
  }
  
  .cookie-button:hover {
	background-color: rgb(240, 240, 240);
  }
  
  .cookie-button:active {
	background-color: rgb(200, 200, 200);
  }
  
  @media (max-width: 768px) {
	.cookie-banner {
	  padding: 20px;
	}
	
	.cookie-button {
	  width: 200px;
	  height: 50px;
	  font-size: 16px;
	}
  }