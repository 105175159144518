@media screen and (max-width: 600px) {
  .player {
    flex-direction: column;
  }

  .player__main {
    height: 100vh;
    margin-top: 70px;
    padding: 0;
  }

  .player__product {
    width: 70vw;
    background-color: rgb(0, 0, 0, 0.2);
    height: 100px;
    position: absolute;
    bottom: 40px;
    display: flex;
    gap: 20px;
    align-items: center;
    box-sizing: border-box;
    border-radius: 0 60px 60px 0;
    border: solid 1px rgb(255, 255, 255, 0.5);
    border-left: none;
    padding: 20px;
    color: #FFF;
  }

  .player__image-container {
    max-width: 50px;
    max-height: 50px;
  }

  .player__product-info {
    max-width: calc(100% - 60px - 70px);
  }

  .player__title {
    font-size: 12px;
    -webkit-line-clamp: 2;
  }

  .player__cart-button {
    background-color: rgb(200, 200, 200);
    height: 50px;
    width: 50px;
    padding: 12px;
  }
  
}