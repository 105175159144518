.page {
  min-height: 100vh;
  max-width: 100vw;
  margin: 0 auto;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  font-family: 'Roboto', 'Lucida Sans', Verdana, sans-serif;
  overflow-x: hidden;
}

.page__main {
  padding-top: 24px;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1300px) {
  .page__main {
    padding-top: 108px;
  }
}

.coming-soon {
  min-widht: 100vw;
}

.coming-soon-m {
  display: none;
}

@media screen and (max-width: 640px) {
  .coming-soon {
    
    display: none;
  }
  
  .coming-soon-m {
    display: flex;
    min-widht: 100vw;
  }
  
}
