.liked {
  padding: 70px 120px;
}

.liked__register {
  font-weight: 300;
  font-size: 24px;
}

.liked__signup-button {
  border: none;
  background: none;
  font-weight: 300;
  font-size: 24px;
  padding: 0;
  color: hsl(331, 92%, 62%);
}

.liked__items {
  margin: 50px auto 0;
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: solid 1px #c28ea7;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
}