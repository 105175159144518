@media screen and (min-width: 600px) and (max-width: 1300px) {
  .product {
    padding: 100px 70px;
  }

  .product__images {
    flex-direction: column-reverse;
  }

  .product__gallery {
    flex-direction: row;
    max-width: 300px;
  }

  .product__image {
    height: 60px;
    width: 60px;
  }

  .product__main-image {
    width: 300px;
    height: 300px;
  }

  .product__image-button {
    height: 40px;
    width: 40px;
  }


  .product__info {
    width: 300px;
  }

  .product__title {
    flex-direction: column;
    gap: 12px;
  }

  .product__name {
    font-size: 22px;
  }

  .product__price {
    font-size: 26px;
  }

  .product__color {
    height: 36px;
    width: 36px;
  }

  .product__buttons {
    gap: 24px;
  }

  .product__cart-button {
    height: 50px;
    font-size: 16px;
  }

  .product__more-button {
    height: 30px;
    width: 30px;
    background-size: cover;
  }
}

@media screen and (max-width: 600px) {
  .product {
    padding: 60px 20px 20px;
  }

  .product__page {
    flex-direction: column;
    display: flex;
    gap: 30px;
  }

  .product__images {
    flex-direction: column-reverse;
  }

  .product__gallery {
    flex-direction: row;
    max-width: 100%;
  }

  .product__image {
    height: 60px;
    width: 60px;
  }

  .product__main-image {
    width: 100%;
    height: 100%;
  }

  .product__image-button {
    height: 40px;
    width: 40px;
  }


  .product__info {
    width: 100%;
  }

  .product__main {
    padding: 0 0 30px;
  }

  .product__title {
    flex-direction: column;
    gap: 12px;
  }

  .product__name {
    font-size: 22px;
  }

  .product__price {
    font-size: 26px;
  }

  .product__color {
    height: 36px;
    width: 36px;
  }

  .product__buttons {
    gap: 24px;
  }

  .product__cart-button {
    height: 50px;
    font-size: 16px;
  }

  .product__more-button {
    height: 30px;
    width: 30px;
    background-size: cover;
  }
}