.product {
  padding: 70px;
  width: 1600px;
}

.product__page {
  display: flex;
  justify-content: space-between;
}

.product__images {
  display: flex;
  gap: 50px;
}

.product__gallery {
  display: flex;
  gap: 5px;
  height: 104px;
}

.product__image {
  border: solid 1px #D0D0D0;
  width: 100px;
  height: 100px;
  cursor: pointer;
  transition: filter 0.2s ease-in-out;
}

.product__image:hover {
  filter: brightness(90%);
}

.product__image:active {
  filter: brightness(85%);
}

.product__current-image {
  display: flex;
  align-items: center;
  position: relative;
}

.product__main-image {
  width: 500px;
  height: 500px;
}

.product__image-button {
  border: none;
  background: none;
  background-size: cover;
  height: 40px;
  width: 40px;
  position: absolute;
  transition: scale 0.2s ease-in-out;
}

.product__image-button:hover {
  scale: 1.05;
}

.product__image-button:active {
  scale: 1;
}

.product__image-button:disabled {
  opacity: 50%;
  scale: 1;
  cursor: auto;
}

.product__image-button_left {
  background-image: url('../../../images/arrow-circle-left.svg');
  left: 20px;
}

.product__image-button_right {
  background-image: url('../../../images/arrow-circle-right.svg');
  right: 20px;
}

.product__image-container {
  position: relative;
  width: 100px;
  height: 100px;
}

.product__image-delete {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #d9534f;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 1;
  padding: 0;
  transform: scale(1);
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.product__image-delete:hover {
  background-color: #c9302c;
  transform: scale(1.1);
}

.product__image-delete:active {
  transform: scale(0.95);
}

.product__info {
  width: 500px;
}

.product__main {
  padding: 0 16px 30px;
  border-bottom: solid 1px #D0D0D0;
}

.product__title {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
}

.product__name {
  font-weight: 500;
  font-size: 30px;
}

.product__price {
  font-weight: 500;
  font-size: 30px;
  margin-left: 20px;
}

.product__old-price {
  text-decoration: line-through;
  font-size: 16px;
  color: #888;
}

.product__text {
  margin-top: 16px;
}

.product__quality {
  color: #606060;
}

.product__colors {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.product__color {
  border: solid 1px #D0D0D0;
  transition: filter 0.2s ease-in-out;
  cursor: pointer;
}

.product__color:hover {
  filter: brightness(85%);
}

.product__color_selected {
  border: solid 2px #000;
}


.product__buttons {
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 40px;
}

.product__cart-button {
  background-color: rgb(60, 60, 60);
  border: none;
  font-size: 20px;
  font-weight: 500;
  color: white;
  width: 300px;
  height: 70px;
  border-radius: 35px;
  box-shadow: 2px 3px 5px #27203650;
  transition: background-color 0.2s ease-in-out;
}

.product__cart-button:hover {
  background-color: rgb(100, 100, 100);
}

.product__cart-button:active {
  background-color: rgb(200, 200, 200);
}

.product__cart-button:disabled {
  background-color: #F0F0F0;
  color: #888;
  box-shadow: none;
  cursor: default;
}

.product__like-button {
  height: 44px;
  width: 44px;
  background: none;
  border: none;
  background-image: url('../../../images/like.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.product__like-button_active {
  background-image: url('../../../images/like\ active.svg');
}

.product__details {
  display: flex;
  flex-direction: column;
  padding: 10px 16px;
  border-bottom: solid 1px #D0D0D0;
  position: relative;
}

.product__details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
}

.product__more-button {
  background: none;
  border: none;
  background-position: center;
  background-repeat: no-repeat;
  height: 60px;
  width: 60px;
  padding: 10px;
}

.product__more-button_plus {
  background-image: url('../../../images/Plus.svg');
}

.product__more-button_minus {
  background-image: url('../../../images/Minus.svg');
}

.product__details-text {
  color: #606060;
  margin-top: 16px;
  display: none;
}

.product__details-text_visible {
  display: block;
}

.product__color-image {
  height: 40px;
  width: 40px;
  z-index: 2;
}


.product__reviews {
  margin-top: 100px;
}

.product__subtitle {
  font-weight: 500;
  font-size: 22px;
}

.product__videos {
  margin-top: 40px;
  display: flex;
  gap: 20px;
}

.product__delete {
  font-family: 'Roboto', 'Lucida Sans', Verdana, sans-serif;
  cursor: pointer;
  background-color: light-red;
  border: none;
  font-size: 20px;
  font-weight: 500;
  width: 200px;
  height: 40px;
  border-radius: 35px;
  box-shadow: 2px 3px 5px #27203650;
  transition: background-color 0.2s ease-in-out;

 
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}

.product__edit-input {
  width: 100%;
  margin: 5px;
  padding: 8px 12px;
  border: 1px solid #D0D0D0;
  border-radius: 8px;
  font-size: inherit;
  font-family: inherit;
  transition: border-color 0.2s ease-in-out;
  background-color: #fff;
}

.product__edit-input:focus {
  border-color: rgb(200, 200, 200);
  outline: none;
}

.product__edit-textarea {
  width: 100%;
  min-height: 120px;
  padding: 12px;
  border: 1px solid #D0D0D0;
  border-radius: 8px;
  font-family: inherit;
  font-size: inherit;
  resize: vertical;
  transition: border-color 0.2s ease-in-out;
  background-color: #fff;
}

.product__edit-textarea:focus {
  border-color: rgb(200, 200, 200);
  outline: none;
}

.product__admin-buttons {
  display: flex;
  gap: 20px;
  margin-top: 30px;
}

.product__edit-button {
  font-family: inherit;
  cursor: pointer;
  background-color: #f7e256;
  border: none;
  font-size: 20px;
  font-weight: 500;
  width: 200px;
  height: 40px;
  border-radius: 35px;
  box-shadow: 2px 3px 5px #27203650;
  transition: background-color 0.2s ease-in-out;
  color: #2c1822;
}

.product__edit-button:hover {
  background-color: #ee88bb;
}

.product__save-button {
  font-family: inherit;
  cursor: pointer;
  background-color: #4CAF50;
  border: none;
  font-size: 20px;
  font-weight: 500;
  width: 200px;
  height: 40px;
  border-radius: 35px;
  box-shadow: 2px 3px 5px #27203650;
  transition: background-color 0.2s ease-in-out;
  color: white;
}

.product__save-button:hover {
  background-color: #45a049;
}

.product__cancel-button {
  font-family: inherit;
  cursor: pointer;
  background-color: #f0f0f0;
  border: none;
  font-size: 20px;
  font-weight: 500;
  width: 200px;
  height: 40px;
  border-radius: 35px;
  box-shadow: 2px 3px 5px #27203650;
  transition: background-color 0.2s ease-in-out;
  color: #606060;
}

.product__cancel-button:hover {
  background-color: #e0e0e0;
}

.product__photo-upload {
  width: 100px;
  height: 100px;
  border: 2px dashed #D0D0D0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: border-color 0.2s ease-in-out;
  background-color: #f8f8f8;
}

.product__photo-upload:hover {
  border-color: rgb(200, 200, 200);
}

.product__photo-input {
  display: none;
}

.product__color-picker {
  margin: 10px 0;
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.product__color-picker::-webkit-color-swatch-wrapper {
  padding: 0;
}

.product__color-picker::-webkit-color-swatch {
  border: none;
  border-radius: 4px;
}

.loading-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.update-success {
  position: fixed;
  top: 20px;
  right: 20px;
  background: #4CAF50;
  color: white;
  padding: 16px;
  border-radius: 4px;
  z-index: 1000;
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from { transform: translateX(100%); }
  to { transform: translateX(0); }
}

@import url('./Product_adaptive.css');