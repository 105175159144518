.dropdown__button {
  margin-left: 50px;
  margin-right: 20px;
  background: none;
  border: none;
  border-radius: 20px;
  height: 40px;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all 0.3s ease-in-out;
  position: relative;
  padding-bottom: 4px;
}

.dropdown__button::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: rgb(200, 200, 200);
  transform: scaleX(0);
  transition: transform 0.7s ease;
}

.dropdown__button:hover::after {
  transform: scaleX(1);
}

.dropdown__button.active::after {
  transform: scaleX(1);
}

.dropdown__links_visible {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

/* Smooth transition for dropdown */
.dropdown__links {
  display: flex;
  border: solid 1px rgb(200, 200, 200);
  border-radius: 12px;
  max-width: 200px;
  box-sizing: border-box;
  background-color: #FFF;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
  position: absolute;
  top: 56px;
  left: 0;
  z-index: 1;
  font-size: 16px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: all 0.3s ease-in-out;
}

.dropdown__links_visible {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.dropdown__link {
  padding: 10px;
  color: #000;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  position: relative;
  transition: all 0.2s ease;
}

.dropdown__link:hover {
  background-color: rgba(240, 240, 240, 0.5);
  padding-left: 15px;
}