.search {
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;
}

.search__input {
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  padding-left: 34px;
  border-radius: 30px;
  border: solid 1px rgb(200, 200, 200);
  font-size: 16px;
}

.search__input:focus {
  outline: none;
}

.search__icon {
  height: 24px;
  width: 24px;
  position: absolute;
  left: 5px;
  top: 8px;
}

.search__button {
  font-size: 16px;
  border: none;
  border-radius: 30px;
  height: 40px;
  width: 100px;
  position: absolute;
  right: 0;
  color: black;
  border: 1px solid rgb(200, 200, 200);
  background: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px;
  z-index: 2;
  transition: background 0.3s, opacity 0.3s, transform 0.2s, box-shadow 0.3s;
  line-height: 16px;
}

.search__button:hover {
  background-color: rgb(250, 249, 246);
  box-shadow: rgba(0, 0, 0, 0.15) 3px 3px 3px;
}

.search__button:active {
  background-color: #080808;
}

@media screen and (min-width: 600px) and (max-width: 1300px) {
  .search {
    order: 3;
    margin: 24px auto 0;
  }
}

@media screen and (max-width: 600px) {
}