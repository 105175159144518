.gallery {
  padding: 60px 70px 70px;
}

.gallery__header {
  display: flex;
  gap: 5px;
  color: #888888;
}

.gallery__link {
  color: #888888;
}

.gallery__current {
  color: #555555;
}

.gallery__items {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.gallery__filters {
  width: 100%;
  background-color: #fff;
}

.gallery__filter-form {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
  padding: 16px 0;
}

.gallery__label {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #2c1822;
}

.gallery__checkbox {
  height: 20px;
  width: 20px;
  cursor: pointer;
  accent-color: rgb(200, 200, 200);
}

.gallery__num-input {
  height: 32px;
  width: 70px;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 16px;
  padding: 0 12px;
  font-size: 14px;
  color: #2c1822;
  transition: border-color 0.2s ease-in-out;
  -moz-appearance: textfield;
}

/* Remove arrows from number inputs */
.gallery__num-input::-webkit-outer-spin-button,
.gallery__num-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.gallery__num-input:focus {
  outline: none;
  border-color: rgb(200, 200, 200);
  box-shadow: 0 0 0 2px rgba(255, 145, 200, 0.1);
}

.gallery__apply-button {
  background-color: rgb(200, 200, 200);
  border: none;
  font-size: 16px;
  font-weight: 500;
  color: #2c1822;
  padding: 8px 24px;
  height: 40px;
  border-radius: 20px;
  box-shadow: 2px 3px 5px #27203650;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
}

.gallery__apply-button:hover {
  background-color: rgb(220, 220, 220);
}

.gallery__apply-button:active {
  background-color: rgb(220, 220, 220);
  box-shadow: 1px 2px 3px #27203650;
  transform: translateY(1px);
}

@media screen and (max-width: 600px) {
  .gallery__filter-form {
    margin-bottom: 80px;
  }

  .gallery__apply-button {
    width: 100%;
  }
}