.catalogue {
  padding: 60px 70px 100px;
  max-width: 1450px;
}

.catalogue__categories {
  display: flex;
  justify-content: center;
  gap: 32px;
}

.catalogue__category {
  font-weight: 300;
  font-size: 20px;
  text-decoration: none;
  color: grey;
  position: relative;
  padding-bottom: 4px;
}

.catalogue__category::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #000;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.catalogue__category:hover::after {
  transform: scaleX(1);
}

.catalogue__category.active::after {
  transform: scaleX(1);
}

.catalogue__category.active {
  color: #000;
}


.catalogue__title {
  margin-top: 20px;
  font-weight: 500;
  font-size: 32px;
  text-aligh: center;
}

.catalogue__products {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.catalogue__category-2 {
margin-top: 40px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.catalogue__subtitle {
  font-size: 26px;
  font-weight: 500;

}

.catalogue__more {
  color: #888888;
}

.catalogue__filter-form {
  display: flex;
  flex-wrap: wrap;
  max-height: 24px;
  gap: 30px;
}

.catalogue__filters {
  margin-top: 24px;
}

.catalogue__label {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.catalogue__checkbox {
  height: 20px;
  width: 20px;
}

.catalogue__num-input {
  height: 20px;
  width: 70px;
  appearance: textfield;
  border: solid 1px gray;
  border-radius: 3px;
  padding: 0 5px;
}

.catalogue__num-input:focus {
  outline: none;
}

.catalogue__gallery {
  position: relative;
  width: 100%;
  margin-top: 20px;
   margin-bottom: 20px;
}

.catalogue__gallery_scroll {

  display: flex;
  max-width: 100%;
  overflow: hidden;
}

.catalogue__gallery_grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;  
}


.catalogue__reviews {
  margin-top: 50px;
}

.catalogue__videos {
  display: flex;
  gap: 20px;
  max-width: 100%;
  position: relative;
  margin-top: 20px;
   margin-bottom: 20px;
}

.catalogue__carousel-btn {
  height: 32px;
  width: 32px;
  background-color: #FFF;
  background-size: cover;
  border: none;
  border-radius: 25px;
  position: absolute;
  top: 40%;
  cursor: pointer;
}

.catalogue__carousel-btn_prev {
  background-image: url('../../../images/arrow-circle-left.svg');
  left: 2px;
}

.catalogue__carousel-btn_next {
  background-image: url('../../../images/arrow-circle-right.svg');
  right: 2px;
}

@import url('./AliceCarousel.css');



@media screen and (max-width: 600px) {
  .catalogue {
    padding: 60px 20px;
  }

  .catalogue__filter-form {
    margin-bottom: 80px;
  }
}