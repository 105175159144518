.error-boundary {
	position: fixed;
	top: 20px;
	right: 20px;
	z-index: 1000;
	display: flex;
	flex-direction: column;
	gap: 10px;
	max-width: 350px;
  }
  
  .error-alert {
	background-color: #fff;
	border: 2px solid #dc3545;
	border-radius: 4px;
	padding: 12px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	animation: slideIn 0.3s ease-out;
	display: flex;
	align-items: flex-start;
  }
  
  .error-content {
	flex: 1;
	margin-right: 10px;
  }
  
  .error-title {
	color: #dc3545;
	font-weight: bold;
	margin: 0 0 5px 0;
	font-size: 16px;
  }
  
  .error-message {
	color: #666;
	margin: 0;
	font-size: 14px;
  }
  
  .error-timestamp {
	color: #999;
	font-size: 12px;
	margin-top: 5px;
  }
  
  .error-dismiss {
	background: none;
	border: none;
	color: #999;
	cursor: pointer;
	font-size: 18px;
	padding: 0;
	line-height: 1;
  }
  
  .error-dismiss:hover {
	color: #666;
  }
  
  @keyframes slideIn {
	from {
	  transform: translateX(100%);
	  opacity: 0;
	}
	to {
	  transform: translateX(0);
	  opacity: 1;
	}
  }
  
  @keyframes slideOut {
	from {
	  transform: translateX(0);
	  opacity: 1;
	}
	to {
	  transform: translateX(100%);
	  opacity: 0;
	}
  }
  
  .error-alert.removing {
	animation: slideOut 0.3s ease-in forwards;
  }